/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 20, 2023 */

@font-face {
  font-family: "villa_pinedo___handwrittenRg";
  src: url("./VillaPinedo_Handwritten-Regular-1.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*
@font-face {
  font-family: "villa_pinedo___handwrittenRg";
  src: url("./villapinedo_handwritten-regular.woff2") format("woff2"),
  url("./villapinedo_handwritten-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
 */

/* avenir */
/* @font-face {
  font-family: "Avenir";
  src: url("./avenir_next.woff2") format("woff2"),
    url("./avenir_next.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Avenir_lightitalic";
  src:
    url("./avenir_lt_35_light_oblique.woff2") format("woff2"),
    url("./avenir_lt_35_light_oblique.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src:
    url("./avenir_lt_std_35_light.woff2") format("woff2"),
    url("./avenir_lt_std_35_light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'avenir_lt_std35_light_oblique';
  src: url('./avenir_lt_std_35_light_oblique.woff2') format('woff2'),
       url('./avenir_lt_std_35_light_oblique.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} */

@font-face {
  font-family: "Avenir_black";
  src:
    url("./avenir_lt_std_95_black.woff2") format("woff2"),
    url("./avenir_lt_std_95_black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'avenir_lt_std95_black_oblique';
  src: url('./avenir_lt_std_95_black_oblique.woff2') format('woff2'),
       url('./avenir_lt_std_95_black_oblique.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} */

/* Fjalla font */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Yq6R-LCAWCX3-6Ky7FAFrOx6kigt.woff2") format("woff2");
}

/* vietnamese */
/* @font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Yq6R-LCAWCX3-6Ky7FAFrO56kigt.woff2") format('woff2');
} */
/* latin-ext */
/* @font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Yq6R-LCAWCX3-6Ky7FAFrO96kigt.woff2") format('woff2');
} */
/* latin */
/* @font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Yq6R-LCAWCX3-6Ky7FAFrOF6kg.woff2") format('woff2');
} */
