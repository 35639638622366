.tiptap {
  @apply min-h-[100px];

  ul,
  ol {
    list-style: revert;
    padding: revert;
  }

  p.is-editor-empty:first-child::before {
    @apply pointer-events-none float-left h-0 text-input-placeholder;
    content: attr(data-placeholder);
  }

  a {
    @apply cursor-pointer text-blue underline;
  }

  blockquote {
    @apply border-l-4 border-gray-300 pl-6;
  }

  p {
    margin: revert;
  }

  p:first-child {
    margin-top: 0;
  }
}

.tiptap-button {
  @apply rounded-lg border border-orange px-3 py-1;

  &:hover {
    @apply bg-orange text-white;
  }

  &.active {
    @apply bg-orange text-white;
  }

  &.inactive {
    @apply bg-transparent text-black;
  }

  &:disabled {
    @apply opacity-50;

    &:hover {
      @apply bg-transparent text-gray-400;
    }
  }
}
